import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss']
})
export class WelcomeComponent implements OnInit {

  constructor(
    private router: Router,
  ) { }

  ngOnInit() {
  }

  goToPatientLogin() {
    // redirect to angular endpoint /login/professional
    // this.router.navigate(["login", "professional"]);

    // if you need to redirect to an external url, you need
    // to comment the code above and uncomment the code below
    // window.location.href = "http://academicteach.ch/";
    
    this.router.navigate(["login", "patient-information"]);
  }

  goToProfessionalLogin() {
    this.router.navigate(["login", "professional"]);
  }

}
