import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { InvitationService } from 'src/app/home/service/invitation.service';
import { ComparisonValidator } from 'src/app/shared/validators/comparison.validator';
import { DifferentValidator } from 'src/app/shared/validators/different.validator';

@Component({
  selector: 'app-accept-invitation',
  templateUrl: './accept-invitation.component.html',
  styleUrls: ['./accept-invitation.component.scss']
})
export class AcceptInvitationComponent implements OnInit {

  public form: FormGroup;
  public submitted = false;
  private formError = false;
  private acceptInvitationSuccess = false;
  navigateTo: string;
  navigateHint = '';

  constructor(
    private formBuilder: FormBuilder,
    private invitationService: InvitationService,
    private router: Router,
    ) {
      this.form = this.formBuilder.group({
        firstname: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(128)]],
        lastname: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(128)]],
        newPassword: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(128)]],
        newPasswordRepeated: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(128)]]
      }, {
        validator: [
          ComparisonValidator('newPassword', 'newPasswordRepeated'),
        ]
      });
     }

  ngOnInit() {
  }

  clearForm() {
    this.form.patchValue({
      firstname: '',
      lastname: '',
      newPassword: '',
      newPasswordRepeated: '',
    })
  }

  userTyped() {
    this.formError = false;
    this.acceptInvitationSuccess = false;
  }

  getUrlData() {
    let paramsObj = {};
    let params = window.location.href.split('?')[1].split('&');
    params.forEach(p => {
      let [key, value] = p.split('=');
      paramsObj[key] = value;
    })
    console.log({paramsObj});
    
    return paramsObj;
  }

  onSubmit() {
    if (this.submitted) return;
    this.submitted = true;
    this.formError = false;
    this.acceptInvitationSuccess = false;

    let params: any = this.getUrlData();

    if (this.form.valid) {
      const body = {
        email: params.id,
        token: params.token,
      };
      Object.assign(body, this.form.value);

      this.invitationService.acceptInvitation(body).subscribe(res => {
        this.acceptInvitationSuccess = true;
        this.submitted = false;
        this.clearForm();
        this.navigateTo = "/login";
        this.navigateHint = "Go back to Login Page"
      }, err => {
        if (err.error.message == "token_expired") {
          this.router.navigate(["/login"]);
        }
        this.formError = true;
        this.submitted = false;
      });
    }
  }
  get firstname() { return this.form.get('firstname'); }
  get lastname() { return this.form.get('lastname'); }
  get newPassword() { return this.form.get('newPassword'); }
  get newPasswordRepeated() { return this.form.get('newPasswordRepeated'); }

}
