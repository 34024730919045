import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from '../service/login.service';

@Component({
  selector: 'app-title',
  templateUrl: './title.component.html',
  styleUrls: ['./title.component.scss']
})
export class TitleComponent implements OnInit {
  @Input() showMenu = false;
  @Input() showBackButton = true;
  @Input() subtitle = '';
  @Input() navigateTo = '';
  @Input() navigateHint = '';
  @Input() title = 'Biomarker';
  @Input() titleLeft = '246px';
  @Input() titleColor = '#c00000';

  menuOpened = false;
  userCanInvite = false;


  constructor(
    private loginService: LoginService,
    private router: Router,
  ) { }

  ngOnInit() {
    this.userCanInvite = sessionStorage.getItem("canInvite") == "true";
  }

  toggleMenu() {
    this.menuOpened = !this.menuOpened;
  }

  logout() {
    this.loginService.logout().subscribe(_ => {
      this.router.navigate(["/login"])
    });
  }

}
