import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppBaseComponent } from '../shared/app-base/app-base.component';
import { AcceptInvitationComponent } from './accept-invitation/accept-invitation.component';
import { ChangePasswordWithTokenComponent } from './change-password-with-token/change-password-with-token.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { ResetComponent } from './reset/reset.component';
import { SignInComponent } from './sign-in/sign-in.component';
import { WelcomeComponent } from './welcome/welcome.component';
import { PatientInformationPageComponent } from './patient-information-page/patient-information-page.component';


const routes: Routes = [
  {
    path: 'login', component: AppBaseComponent, children: [
      { path: '', component: WelcomeComponent },
      { path: 'professional', component: SignInComponent },
      { path: 'reset', component: ResetComponent },
      { path: 'password/change', component: ChangePasswordComponent },
      { path: 'password/change-with-token', component: ChangePasswordWithTokenComponent },
      { path: 'accept-invitation', component: AcceptInvitationComponent },
      { path: 'patient-information', component: PatientInformationPageComponent },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],

  exports: [RouterModule]
})
export class LoginRoutingModule { }
