import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppBaseComponent } from '../shared/app-base/app-base.component';
import { SelectSystemComponent } from './select-system/select-system.component';
import { UserInvitationComponent } from './user-invitation/user-invitation.component';


const routes: Routes = [
  {
    path: 'home', component: AppBaseComponent, children: [
      { path: '', component: SelectSystemComponent },
      { path: 'select-system', component: SelectSystemComponent },
      { path: 'invite', component: UserInvitationComponent },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class HomeRoutingModule { }
