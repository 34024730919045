import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { System } from 'src/app/model/system';
import { InvitationService } from '../service/invitation.service';

@Component({
  selector: 'app-user-invitation',
  templateUrl: './user-invitation.component.html',
  styleUrls: ['./user-invitation.component.scss']
})
export class UserInvitationComponent implements OnInit {
  allowedSystems: Array<System>;
  form: FormGroup;
  showInvitationSuccess = false;
  formError = false;

  constructor(
    private formBuilder: FormBuilder,
    private invitationService: InvitationService,
  ) { }

  ngOnInit() {
    this.allowedSystems = JSON.parse(sessionStorage.getItem("allowedSystems")).filter(system => system.canInvite);
    
    this.form = this.formBuilder.group({
      email: [null, [Validators.required, Validators.email]],
      // systems: [null, [Validators.required]],
      systems: this.buildSystemsForm(),
    })
  }

  buildSystemsForm() {
    const values = this.allowedSystems.map(_ => new FormControl(false));
    return this.formBuilder.array(values, this.requiredMinCheckbox(1));
  }

  requiredMinCheckbox(min = 1) {
    const validator = (formArray: FormArray) => {
      const totalChecked = formArray.controls
        .map(v => v.value)
        .reduce((total, current) => current ? total + current : total, 0);

        return totalChecked >= min ? null : { required: true }
    };
    return validator;
  }

  onSubmit() {
    if (!this.form.valid || this.form.pending) {
      return false;
    }
    this.formError = false;
    let formData = Object.assign({}, this.form.value);
    let selectedSystems = []
    formData.systems.forEach((isChecked, i) => isChecked && selectedSystems.push(this.allowedSystems[i]));
    formData.systems = selectedSystems;

    this.invitationService.invite(formData).subscribe(res => {
      this.showInvitationSuccess = true;
    }, error => {
      this.formError = true;
      console.log(error);
    });
  }

}
