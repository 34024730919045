import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../shared/shared.module';
import { ChangePasswordWithTokenComponent } from './change-password-with-token/change-password-with-token.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { LoginRoutingModule } from './login-routing.module';
import { ResetComponent } from './reset/reset.component';
import { SignInComponent } from './sign-in/sign-in.component';
import { WelcomeComponent } from './welcome/welcome.component';
import { AcceptInvitationComponent } from './accept-invitation/accept-invitation.component';
import { PatientInformationPageComponent } from './patient-information-page/patient-information-page.component';


@NgModule({
  declarations: [SignInComponent, ResetComponent, ChangePasswordWithTokenComponent, ChangePasswordComponent, WelcomeComponent, AcceptInvitationComponent, PatientInformationPageComponent],
  imports: [
    CommonModule,
    HttpClientModule,
    ReactiveFormsModule,
    LoginRoutingModule,
    SharedModule
  ]
})
export class LoginModule { }
