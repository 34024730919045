import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-patient-information-page',
  templateUrl: './patient-information-page.component.html',
  styleUrls: ['./patient-information-page.component.scss']
})
export class PatientInformationPageComponent implements OnInit {

  constructor() { }

  patientPageList: Array<any>;

  ngOnInit() {
    this.patientPageList = [
      { name: "Roche FOCUS MENSCH", url: "https://roche-fokus-mensch.ch", sameWindow: true },
      { name: "Bayer Schweiz Pharmaceuticals", url: "https://pharma.bayer.ch" },
    ];
  }

  goToPatientPage(page) {
    if (page.sameWindow === false) {
      //open in a new tab
      window.open(page.url);
    } else {
      window.location.href = page.url;
    }
  }

}
