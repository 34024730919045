import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AppBaseComponent } from "./shared/app-base/app-base.component";
import { CookiesComponent } from "./shared/cookies/cookies.component";
import { PrivacyPoliceComponent } from "./shared/privacy-police/privacy-police.component";
import { TermsOfUseComponent } from "./shared/terms-of-use/terms-of-use.component";

const routes: Routes = [
  { path: "", redirectTo: "login", pathMatch: "full" },
  { path: 'terms-of-use', component: AppBaseComponent, children: [
    { path: '', component: TermsOfUseComponent },
  ] },
  { path: 'privacy-police', component: AppBaseComponent, children: [
    { path: '', component: PrivacyPoliceComponent },
  ] },
  { path: 'cookies', component: AppBaseComponent, children: [
    { path: '', component: CookiesComponent },
  ] },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
