import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { LoginService } from 'src/app/shared/service/login.service';
import { ComparisonValidator } from 'src/app/shared/validators/comparison.validator';

@Component({
  selector: 'app-change-password-with-token',
  templateUrl: './change-password-with-token.component.html',
  styleUrls: ['./change-password-with-token.component.scss']
})
export class ChangePasswordWithTokenComponent implements OnInit {

  public form: FormGroup;
  public submitted = false;
  formError: boolean = false;
  
  private id:number = null;
  private token:string = null;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private loginService: LoginService,
    private router: Router,
    ) {
      this.form = this.formBuilder.group({
        newPassword: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(128)]],
        newPasswordRepeated: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(128)]]
      }, {
        validator: ComparisonValidator('newPassword', 'newPasswordRepeated')
      });
     }

  ngOnInit() {
    this.id = this.route.snapshot.queryParams.id;
    this.token = this.route.snapshot.queryParams.token
  }

  onSubmit() {
    this.submitted = true;
    this.formError = false;

    if (this.form.valid) {
      let newPassword = this.form.getRawValue()["newPassword"];
      const body = {
        id: this.id,
        token: this.token,
        password: newPassword
      };

      this.loginService.changePasswordWithToken(body).subscribe(res => {
        this.router.navigate(["login", "professional"]);
      }, err => {
        this.formError = true;
      });
    }
  }
  get newPassword() { return this.form.get('newPassword'); }
  get newPasswordRepeated() { return this.form.get('newPasswordRepeated'); }

}
