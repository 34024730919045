import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LoginService } from 'src/app/shared/service/login.service';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
  })
  export class AuthInterceptorService implements HttpInterceptor {

    constructor(
        private loginService: LoginService,
        ) { }

    intercept(req: HttpRequest<any>, next: HttpHandler) {
        let sameHost = isRequestSameHost(req);
        if (sameHost && sessionStorage.getItem('username') && sessionStorage.getItem('token')) {
            let token = this.loginService.getToken();
            req = req.clone({
                setHeaders: {
                    'Authorization': `Bearer ${token}`
                }
            })
        }

        return next.handle(req);
    }
  }

function isRequestSameHost(req: HttpRequest<any>) {
    let requestAddress = getAddress(req.url).toLowerCase();
    let currentAddress = getAddress(location.href).toLowerCase();
    let backendAddress = getAddress(environment.apiBaseUrl).toLowerCase();
    return ([backendAddress, currentAddress].includes(requestAddress));
}
function getAddress(url: string) {
    //http://localhost:4201/login/select-system
    return url.substring(0, url.indexOf("/", 8));
}

