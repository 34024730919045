import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LoginService } from 'src/app/shared/service/login.service';
import { ComparisonValidator } from 'src/app/shared/validators/comparison.validator';
import { DifferentValidator } from 'src/app/shared/validators/different.validator';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {

  public form: FormGroup;
  public submitted = false;
  private formError = false;
  private changePasswordSuccess = false;
  
  private id:number = null;
  private token:string = null;

  constructor(
    private formBuilder: FormBuilder,
    private loginService: LoginService,
    private router: Router,
    ) {
      this.form = this.formBuilder.group({
        oldPassword: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(128)]],
        newPassword: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(128)]],
        newPasswordRepeated: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(128)]]
      }, {
        validator: [
          DifferentValidator('oldPassword', 'newPassword'),
          ComparisonValidator('newPassword', 'newPasswordRepeated'),
        ]
      });
     }

  ngOnInit() {
  }

  clearForm() {
    this.form.patchValue({
      oldPassword: '',
      newPassword: '',
      newPasswordRepeated: '',
    })
  }
  userTyped() {
    this.formError = false;
    this.changePasswordSuccess = false;
  }

  onSubmit() {
    if (this.submitted) return;
    this.submitted = true;
    this.formError = false;
    this.changePasswordSuccess = false;

    if (this.form.valid) {
      let oldPassword = this.form.getRawValue()["oldPassword"];
      let newPassword = this.form.getRawValue()["newPassword"];
      const body = {
        userId: this.loginService.getUserId(),
        oldPassword: oldPassword,
        newPassword: newPassword,
      };

      this.loginService.changePassword(body).subscribe(res => {
        this.changePasswordSuccess = true;
        this.submitted = false;
        this.clearForm();
      }, err => {
        if (err.error.message == "token_expired") {
          this.router.navigate(["/login"]);
        }
        this.formError = true;
        this.submitted = false;
      });
    }
  }
  get oldPassword() { return this.form.get('oldPassword'); }
  get newPassword() { return this.form.get('newPassword'); }
  get newPasswordRepeated() { return this.form.get('newPasswordRepeated'); }

}
