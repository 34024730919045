import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import { System } from "src/app/model/system";
import { LoginService } from "src/app/shared/service/login.service";

@Component({
  selector: "app-select-system",
  templateUrl: "./select-system.component.html",
  styleUrls: ["./select-system.component.scss"],
})
export class SelectSystemComponent implements OnInit {
  allowedSystems: Array<System>;
  userCanInvite = false;
  showMenu: boolean = false;
  token: String;
  form: FormGroup;

  constructor(
    private router: Router,
    private loginService: LoginService,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit() {
    if (!this.loginService.getToken()) {
      this.router.navigate(["login"]);
    }
    this.allowedSystems = JSON.parse(sessionStorage.getItem("allowedSystems"));
    
    this.token = this.getToken();
    this.form = this.formBuilder.group({
      token: [null],
    });
  }

  selectSystem(system) {
    let formSystem = <HTMLFormElement>document.getElementById("formSystem");
    formSystem.action = system.url;
    formSystem.submit();
  }

  getToken() {
    return sessionStorage.getItem("token");
  }
}
