import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  private apiUrl: string;
  private headers: HttpHeaders = new HttpHeaders({ 'Content-Type': 'application/json'});

  login(formData: any) {
    return this.http.post<any>(`${this.apiUrl}/login`, formData)
    .pipe(
      tap(res => {
        sessionStorage.setItem('userId', res.userId);
        sessionStorage.setItem('canInvite', res.canInvite);
        sessionStorage.setItem('username', res.username);
        sessionStorage.setItem('token', res.token);
        sessionStorage.setItem('allowedSystems', JSON.stringify(res.systems));
      })
    );
  }

  logout() {
    return this.http.post<any>(`${this.apiUrl}/portal-logout`, {});
  }

  isTokenExpired() {
    let tokenPayload = JSON.parse(atob(this.getToken().split('.')[1]));
    console.log(tokenPayload.exp, Math.floor(Date.now() / 1000));
    return (tokenPayload.exp < Math.floor(Date.now() / 1000));
  }

  getToken() {
    return sessionStorage.getItem("token");
  }

  getUserId() {
    return sessionStorage.getItem("userId");
  }

  resetPassword(email:string) {
    return this.http.post<any>(`${this.apiUrl}/public/password/reset?email=${email}`, {});
  }

  changePassword(body: any) {
    return this.http.put<any>(`${this.apiUrl}/user/password`, body);
  }

  changePasswordWithToken(body: any) {
    return this.http.post<any>(`${this.apiUrl}/public/password/change`, body);
  }

  constructor(private http: HttpClient) {
    this.apiUrl = environment.apiBaseUrl;
  }
}
