import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../shared/shared.module';

import { HomeRoutingModule } from './home-routing.module';
import { SelectSystemComponent } from './select-system/select-system.component';
import { UserInvitationComponent } from './user-invitation/user-invitation.component';


@NgModule({
  declarations: [SelectSystemComponent, UserInvitationComponent],
  imports: [
    CommonModule,
    HomeRoutingModule,
    ReactiveFormsModule,
    SharedModule,
  ]
})
export class HomeModule { }
