import { FormGroup } from '@angular/forms';

export function DifferentValidator(oldPassword: string, newPassword: string) {
    return (formGroup: FormGroup) => {

        const oldPasswordField = formGroup.controls[oldPassword];
        const newPasswordField = formGroup.controls[newPassword];

        if (newPasswordField.errors && !newPasswordField.errors.different) {
            return;
        }

        if (oldPasswordField.value === newPasswordField.value) {
            newPasswordField.setErrors({ different: true });
        } else {
            newPasswordField.setErrors(null);
        }
    }
}