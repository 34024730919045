import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class InvitationService {
  private apiUrl: string;

  constructor(private http: HttpClient) {
    this.apiUrl = environment.apiBaseUrl;
  }
  
  invite(formData: any) {
    return this.http.post<any>(`${this.apiUrl}/invitation/send`, formData);
  }
  
  acceptInvitation(formData: any) {
    return this.http.post<any>(`${this.apiUrl}/invitation/accept`, formData);
  }
}
