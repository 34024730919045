import { FormGroup } from '@angular/forms';

export function ComparisonValidator(newPassword: string, newPasswordRepeated: string) {
    return (formGroup: FormGroup) => {
        
        const password = formGroup.controls[newPassword];
        const passwordRepeated = formGroup.controls[newPasswordRepeated];

        if (passwordRepeated.errors && !passwordRepeated.errors.compare) {
            return;
        }

        if (password.value !== passwordRepeated.value) {
            passwordRepeated.setErrors({ compare: true });
        } else {
            passwordRepeated.setErrors(null);
        }
    }
}