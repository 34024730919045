import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LoginService } from 'src/app/shared/service/login.service';

@Component({
  selector: 'app-reset',
  templateUrl: './reset.component.html',
  styleUrls: ['./reset.component.scss']
})
export class ResetComponent implements OnInit {

  form: FormGroup;
  formError: boolean = false;

  constructor(
    private formBuilder: FormBuilder, 
    private loginService: LoginService,
    private router: Router,
    ) { }

  ngOnInit() {
    this.form = this.formBuilder.group({
      username: [null, [Validators.required, Validators.email]]
    })
  }

  onSubmit() {
    if (!this.form.valid || this.form.pending) {
      return false;
    }
    
    sessionStorage.clear();
    this.formError = false;
    const formData = this.form.getRawValue();
    this.loginService.resetPassword(formData.username).subscribe(res => {
      this.router.navigate(["login", "professional"]);
    }, error => {
      this.formError = true;
      console.log(error);
    });
  }

}
