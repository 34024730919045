import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { GoBackComponent } from './go-back/go-back.component';
import { AppBaseComponent } from './app-base/app-base.component';
import { TermsOfUseComponent } from './terms-of-use/terms-of-use.component';
import { CookiesComponent } from './cookies/cookies.component';
import { PrivacyPoliceComponent } from './privacy-police/privacy-police.component';
import { RouterModule } from '@angular/router';
import { TitleComponent } from './title/title.component';


@NgModule({
  declarations: [GoBackComponent, AppBaseComponent, CookiesComponent, PrivacyPoliceComponent, TermsOfUseComponent, TitleComponent],
  imports: [
    CommonModule,
    RouterModule
  ],
  exports: [GoBackComponent, TitleComponent],
})
export class SharedModule { }
