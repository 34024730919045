import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LoginService } from 'src/app/shared/service/login.service';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss']
})
export class SignInComponent implements OnInit {

  form: FormGroup;
  formError: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private loginService: LoginService,
    private router: Router,
    ) { }

  ngOnInit() {
    this.form = this.formBuilder.group({
      username: [null, [Validators.required, Validators.email]],
      password: [null, [Validators.required, Validators.minLength(8)]],
    })
  }

  onSubmit() {
    if (!this.form.valid || this.form.pending) {
      return false;
    }
    this.formError = false;
    const formData = this.form.getRawValue();
    this.loginService.login(formData).subscribe(res => {
      this.router.navigate(["home", "select-system"]);
    }, error => {
      this.formError = true;
      console.log(error);      
    });
  }

  get password() { return this.form.get('password') };
}
